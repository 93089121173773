import * as React from 'react';
import logoPng from '../../../assets/esteco-logo.png';
import estecoLinksData from '../../../content/links/esteco-links-content.json';
import Sign from '../../soul/layout/sign/Sign';
import { IconSize } from '../../soul/layout/sign/IconSize';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const MainNavbar: React.FC = () => (
    <nav className="ew-global-nav">
        <div className="centered-column main-navbar">
                <a href={estecoLinksData.esteco.href}>
                    <img src={logoPng} height="65px" alt="ESTECO's logo"/>
                </a>
                <div>
                    <a href={estecoLinksData.esteco.href} className="h-text-on-dark a-button  a-button--ghost-link">
                        <Sign icon="a-icon--forward" iconSize={IconSize.ICON_M} label={estecoLinksData.esteco.label} reverse/>
                    </a>
                </div>
        </div>
    </nav>
);

export default MainNavbar;
