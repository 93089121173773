import * as React from 'react';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface FlagProps {
    reverse?: boolean,
    mobile?: boolean,
    children: React.ReactNode[]
}

const Flag: React.FC<FlagProps> = (props) => (
    <div className={`l-flag  ${props.reverse ? 'l-flag--rev' : ''} ${props.mobile ? 'l-flag--mobile' : ''}`}>
        <div className="l-flag__image">
            {props.children[0]}
        </div>
        <div className="l-flag__body">
            {props.children[1]}
        </div>
    </div>
);

export default Flag;
