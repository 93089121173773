import * as React from 'react';
import BasePageLayout from '../BasePageLayout';
import style from './singleColumnLayout.module.scss';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const SingleColumnLayout: React.FC = ({children}) => (
    <BasePageLayout>
        <div className={style.centerHorizontally}>
            <div className="full-width">
                {children}
            </div>
        </div>
    </BasePageLayout>
);

export default SingleColumnLayout;
