import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Flag from '../../soul/layout/flag/Flag';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const Hero: React.FC = () => (
<StaticQuery
    query={graphql`
        query RetrieveContent {
            file(name: {eq: "hero-content"}) {
                childIndexJson {
                    title
                    umLogo {
                        publicURL
                    }

                    umCube {
                        publicURL
                    }
                }
            }
        }
    `}
    render={(data) => {
        const getImage = (imageResult: any) => <img className="hero__cube" src={imageResult.publicURL} alt="cube"/>;
        return (
            <div className="hero soul-space-stack-both-xl">
                <div className="centered-column">
                        <Flag mobile>
                            <div className="hero__cube">
                                {getImage(data.file.childIndexJson.umCube)}
                            </div>
                            <div className="soul-space-stack-both-xl">
                                <div className="hero__payoff soul-space-stack-bottom-xl">
                                    {data.file.childIndexJson.title}
                                </div>
                                {getImage(data.file.childIndexJson.umLogo)}
                            </div>
                        </Flag>
                </div>
            </div>
        );
    }}
/>);

export default Hero;
