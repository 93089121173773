import * as React from 'react';

import '../styles/main.scss';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import SEO from '../components/SEO/SEO';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const BasePageLayout: React.FC = ({children}) => (
    <React.Fragment>
        <SEO/>
        <Header/>
        <main id="js-main-content">
            {children}
        </main>
        <Footer/>
    </React.Fragment>
);

export default BasePageLayout;
